<template>
  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280" >
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Послуги по абоненту
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <MainModal
            :main="{ component: 'FlatServiceLegacy', title: 'Послуги по абоненту' }"
            :button="{ icon: true, color: 'grey lighten-4', buttonIcon: 'mdi-plus' }"
            :item="{ person_hash: $route.params.person_hash, date_start: current_month, flat_name: flat.address }"
            @updateItemModal="flatIndicatorUpdateItemModal"
        />
      </v-toolbar>
    </v-card-title>
    <v-data-table
        :headers="houseParameterHeaders"
        :items="flat_services"
        hide-default-footer
        class="custom-table custom-table-1"
        @click:row="openFlatIndicatorModal"
    >
      <template v-slot:item.icon="{ item }">
        <MainModal
            :main="{ component: 'FlatServiceLegacy', title: 'Послуги по абоненту' }"
            :button="{ iconActivator: true, class: 'mr-2', color: getIcon(item.icon, 'color'), buttonIcon: getIcon(item.icon, 'icon'), iconSize: 26 }"
            :item="{
                      person_hash: $route.params.person_hash,
                      id: item.id,
                      service_id: item.service_id,
                      date_start: item.date_start,
                      date_end: item.date_end,
                      by_season: item.by_season,
                      month_start: item.month_start,
                      month_end: item.month_end,
                      update: true,
                      flat_name: flat.address,
                      contract_id: item.contract_id,
                    }"
            :modal="flat_service_dialog_id === item.id"
            @updateItemModal="flatIndicatorUpdateItemModal"
        />
      </template>

      <template v-slot:item.date_start="{ item }">
        <span>
          {{ item.date_start | formatDate }}
        </span>
      </template>
      <template v-slot:item.date_end="{ item }">
        <span>
          {{ item.date_end | formatDate }}
        </span>
      </template>
      <template v-slot:item.by_season="{ item }">
        <v-simple-checkbox :value="item.by_season" color="success" class="mt-0"/>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import MainModal from "@/components/modal/MainModal";
import {mapActions, mapGetters} from "vuex";
import {GET_ALL_LEGACY_SERVICE} from "@/store/actions/flat_service";
import {accountIcons} from "@/utils/icons";

export default {
  name: "HWP_FlatServices",
  components: { MainModal },
  data() {
    return {
      flat_service_dialog_id: 0,
      houseParameterHeaders: [
        { text: '', value: 'icon', width: 26 },
        { text: 'Послуга', value: 'service_name' },
        { text: 'Договір', value: 'contract_name' },
        { text: 'Початок', value: 'date_start' },
        { text: 'Закінчення', value: 'date_end' },
        { text: 'Сезонна', value: 'by_season' },
        { text: 'Місяць з', value: 'month_start' },
        { text: 'Місяць по', value: 'month_end' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      flat_services: 'getFlatServices',
      flat: 'getLegacyFlat',
      current_month: 'getCurrentMonthLegacy',
    }),
  },
  methods: {
    ...mapActions({
      fetchFlatServices: GET_ALL_LEGACY_SERVICE
    }),
    getIcon(name, type) {
      const defaultIcon = {icon: 'mdi-text-box-check-outline', color: 'success'}
      const value = accountIcons[name] || defaultIcon
      return value[type]
    },
    flatIndicatorUpdateItemModal(payload) {
      this.flat_service_dialog_id = 0
      if (payload) {
        if (payload.payload === 'fetch') {
          this.$emit('fetch')
        }
      }
    },
    openFlatIndicatorModal(row) {
      this.flat_service_dialog_id = row.id
    }
  },
  mounted() {
    if (this.$route.params.person_hash) {
      this.fetchFlatServices(this.$route.params.person_hash)
    }
  },
  watch: {
    person_hash: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.fetchFlatServices(payload)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>