var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"fill-height",staticStyle:{"border-left":"3px solid #5bb55f"},attrs:{"tile":"","elevation":"1","min-height":"280"}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{staticClass:"pa-0",attrs:{"elevation":"0"}},[_c('v-toolbar-title',{staticClass:"toolbar-work-place-title"},[_vm._v(" Послуги по абоненту ")]),_c('v-spacer'),_c('MainModal',{attrs:{"main":{ component: 'FlatServiceLegacy', title: 'Послуги по абоненту' },"button":{ icon: true, color: 'grey lighten-4', buttonIcon: 'mdi-plus' },"item":{ person_hash: _vm.$route.params.person_hash, date_start: _vm.current_month, flat_name: _vm.flat.address }},on:{"updateItemModal":_vm.flatIndicatorUpdateItemModal}})],1)],1),_c('v-data-table',{staticClass:"custom-table custom-table-1",attrs:{"headers":_vm.houseParameterHeaders,"items":_vm.flat_services,"hide-default-footer":""},on:{"click:row":_vm.openFlatIndicatorModal},scopedSlots:_vm._u([{key:"item.icon",fn:function({ item }){return [_c('MainModal',{attrs:{"main":{ component: 'FlatServiceLegacy', title: 'Послуги по абоненту' },"button":{ iconActivator: true, class: 'mr-2', color: _vm.getIcon(item.icon, 'color'), buttonIcon: _vm.getIcon(item.icon, 'icon'), iconSize: 26 },"item":{
                    person_hash: _vm.$route.params.person_hash,
                    id: item.id,
                    service_id: item.service_id,
                    date_start: item.date_start,
                    date_end: item.date_end,
                    by_season: item.by_season,
                    month_start: item.month_start,
                    month_end: item.month_end,
                    update: true,
                    flat_name: _vm.flat.address,
                    contract_id: item.contract_id,
                  },"modal":_vm.flat_service_dialog_id === item.id},on:{"updateItemModal":_vm.flatIndicatorUpdateItemModal}})]}},{key:"item.date_start",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date_start))+" ")])]}},{key:"item.date_end",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date_end))+" ")])]}},{key:"item.by_season",fn:function({ item }){return [_c('v-simple-checkbox',{staticClass:"mt-0",attrs:{"value":item.by_season,"color":"success"}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }